import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'

import team from '../team'
import TeamMember from './TeamMember'
import { LandingTitle, LandingSubtitle } from './Text'
import Divider from './Divider'
import cx from '../utils/cx'

const TeamSection = () => {
  const { t } = useTranslation()

  const data = useStaticQuery(graphql`
    {
      stefan: file(relativePath: { eq: "team/stefan.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 500
            placeholder: DOMINANT_COLOR
            layout: CONSTRAINED
          )
        }
      }
      ahmed: file(relativePath: { eq: "team/ahmed.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 500
            placeholder: DOMINANT_COLOR
            layout: CONSTRAINED
          )
        }
      }
      eve: file(relativePath: { eq: "team/eve.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 500
            placeholder: DOMINANT_COLOR
            layout: CONSTRAINED
          )
        }
      }
      chrysa: file(relativePath: { eq: "team/chrysa.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 500
            placeholder: DOMINANT_COLOR
            layout: CONSTRAINED
          )
        }
      }
    }
  `)

  return (
    <section className={cx('pt-20 md:pt-28')}>
      <LandingTitle>{t('team.title')}</LandingTitle>
      <LandingSubtitle>
        <Trans
          i18nKey="team.subtitle-html"
          components={[<span className="font-bold text-blue" />]}
        >
          Our human power.
        </Trans>
      </LandingSubtitle>
      <Divider />
      <div className="mt-12 bg-blue-high pb-12 pt-16 md:pb-20">
        <div className="mx-auto max-w-xl px-8 md:max-w-4xl xl:max-w-6xl">
          <div className="-mt-10 lg:-ml-4 lg:flex lg:flex-wrap xl:-ml-10">
            <TeamMember profile={team.stefan} img={getImage(data.stefan)} />

            <TeamMember profile={team.ahmed} img={getImage(data.ahmed)} />

            <TeamMember profile={team.eve} img={getImage(data.eve)} />

            <TeamMember profile={team.chrysa} img={getImage(data.chrysa)} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default TeamSection
